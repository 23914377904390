














































































import {Vue, Component, Mixins} from 'vue-property-decorator'
import {SingleItem} from "@/handlers/interfaces/ContentUI"
import SessionMixin from "@/mixins/SessionMixin"
import User from "@/models/User"
import Handler from "@/handlers/Handler";
import UserService from "@/services/UserService";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";

@Component({})
export default class UserMeView extends Mixins(SessionMixin) {

    lang: any = getModule(LangModule).lang
    loading: boolean = false
    user: SingleItem<User> = { item: new User() }

    created() {
      this.refresh()
    }

    async refresh() {
        await Handler.getItem(this, this.user, () => UserService.getMyUser())
    }

    async update() {
        await Handler.getItem(this, this.user, () => UserService.patchMe(this.user.item.id!!, this.user.item))
    }

}
